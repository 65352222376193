<template>
  <div
    class="multisteps-form__panel border-radius-xl bg-white"
    data-animation="FadeIn"
  >
    <h5 class="font-weight-bolder mb-0">About me</h5>
    <div class="multisteps-form__content">
      <div class="row mt-3">
        <div class="col-12 col-sm-6">
          <material-input v-model="data.firstName" id="firstName" label="First Name" variant="dynamic" :isRequired="true" />
          <div v-show="validation.firstName">Please enter a name</div>
        </div>
        <div class="col-12 col-sm-6 mt-3 mt-sm-0">
          <material-input v-model="data.lastName" id="lastName" label="Last Name" variant="dynamic" :isRequired="true" />
          <div v-show="validation.lastName">Please enter a last name</div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-12 col-sm-6 mt-0">
          <material-input id="age" v-model="data.age" label="Age" variant="dynamic" :isRequired="true" />
          <div v-show="validation.age">Please enter your age</div>
        </div>
      <div class="row mt-3">
        <div class="input-group input-group-static col-12 col-sm-6 mt-3 sm-0">
          <label for="genderSelect" class="ms-0">Select your biological sex from the list of available options.</label>
          <select class="form-control" v-model="data.gender" id="genderSelect" required="true">
            <option value="1" >Female</option>
            <option value="2" >Male</option>
          </select>
        </div>
        <div v-show="validation.gender">Please select your gender</div>
      </div>
      </div>
      <div class="button-row d-flex mt-4">
        <material-button
          type="button"
          color="light"
          variant="gradient"
          class="js-btn-prev"
          @click.prevent="previousStep"
          >Prev</material-button
        >
        <material-button
          type="button"
          color="dark"
          variant="gradient"
          class="ms-auto mb-0 js-btn-next"
          @click.prevent="nextStep"
          >Next</material-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import MaterialInput from "@/components/MaterialInput.vue";
import MaterialButton from "@/components/MaterialButton.vue";

export default {
  name: "Information",
  components: {
    MaterialInput,
    MaterialButton,
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data: {
        firstName: "",
        lastName: "",
        age: "",
        gender: "",
      },
      validation: {
        firstName: false,
        lastName: false,
        age: false,
        gender: false
      },
    };
  },
  methods: {
    previousStep() {
      if (!this.validate())
        return;

      this.$emit("userupdated", this.data);
      this.$parent.prevStep();
    },
    nextStep() {
      if (!this.validate())
        return;

      this.$emit("userupdated", this.data);
      this.$parent.nextStep();
    },
    validate() {
      let isValid = true;

      // Validate First Name
      if (!this.data.firstName) {
        isValid = false;
        this.validation.firstName = true;
      } else {
        this.validation.firstName = false;
      }

      // Validate Last Name
      if (!this.data.lastName) {
        isValid = false;
        this.validation.lastName = true;
      } else {
        this.validation.lastName = false;
      }

      // Validate Age
      if (!this.data.age) {
        isValid = false;
        this.validation.age = true;
      } else {
        this.validation.age = false;
      }

      // Validate Gender
      if (!this.data.gender) {
        isValid = false;
        this.validation.gender = true;
      } else {
        this.validation.gender = false;
      }

      return isValid;
    }
  },
  mounted() {
    this.data.firstName = this.userInfo.firstName;
    this.data.lastName = this.userInfo.lastName;
    this.data.age = this.userInfo.age;
    this.data.gender = this.userInfo.gender;
  },
};
</script>
